<style lang="less" scoped>
  @import "../../assets/css/variables";

  .customer-detail {
    // width: 40%;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-bottom: 50px;
    top: 15%;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/device/list' }">
          活跃设备
        </el-breadcrumb-item>
        <el-breadcrumb-item>设备操作日志</el-breadcrumb-item>
      </el-breadcrumb>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getCustomerByName">
      <el-col :span="24" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 200px"
              v-model="filters.key"
              placeholder="请输入操作人"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getCustomerByName">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="dvclogList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remarks"
          min-width="100"
          :show-overflow-tooltip="true"
          label="操作事件"
        ></el-table-column>
        <el-table-column
          prop="name"
          min-width="100"
          :show-overflow-tooltip="true"
          label="操作人"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="操作时间"
          :show-overflow-tooltip="true"
          min-width="100"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button
          type="danger"
          @click="batchRemove"
          :disabled="!this.sels.length > 0 || !editPermission"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
  </div>
</template>

<script>
  import { getDvclog, delDvclog } from "../../services/device";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";

  export default {
    data() {
      return {
        editPermission: true,
        loaded: false,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        dvclogList: [],
        filters: {
          key: "",
        },
        mac: "",
        listLoading: false,
        sels: [], //列表选中列
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      formatTime(row) {
        return row && row.createTime
          ? moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
      /**
       * 判断编辑权限
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        console.log(this.user);
        this.editPermission = permissions.some((item) => {
          return item === "Customer:update";
        });
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delDvclog(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryDvclogs();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delDvclog(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryDvclogs();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据客户名筛选
       */
      getCustomerByName() {
        this.queryDvclogs();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryDvclogs();
        if (this.loaded) {
          this.pager.page = page;
          // window.router.replace({path: 'systemlog', query: {page: page}})
          this.queryDvclogs();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryDvclogs();
      },

      /**
       * 获取设备日志
       */
      async queryDvclogs() {
        this.listLoading = true;
        let searchParam = Object.assign({}, this.filters);
        const responseData = await getDvclog(this.mac, searchParam, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.dvclogList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      this.mac = this.$route.params.id;
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryDvclogs();
    },
  };
</script>
